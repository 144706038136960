/**
 * Returns the release version of the application.
 *
 * @return {string} The release version of the application.
 */
export function releaseVersion(): string {
  return __RELEASE_VER__
}

export function isProdBuild(): boolean {
  return __IS_PROD_BUILD__
}

export function downloadFile(uri: string, filename: string) {
  const link = document.createElement('a')
  link.download = filename
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
