<template>
  <transition
    name="fade-expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    enter(element: Element) {
      const htmlElement = element as HTMLElement
      const width = getComputedStyle(htmlElement).width

      htmlElement.style.width = width
      htmlElement.style.position = 'absolute'
      htmlElement.style.visibility = 'hidden'
      htmlElement.style.height = 'auto'

      const height = getComputedStyle(htmlElement).height

      htmlElement.style.width = ''
      htmlElement.style.position = ''
      htmlElement.style.visibility = ''
      htmlElement.style.height = '0'

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(htmlElement).height

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        htmlElement.style.height = height
      })
    },
    afterEnter(element: Element) {
      const htmlElement = element as HTMLElement
      htmlElement.style.height = 'auto'
    },
    leave(element: Element) {
      const htmlElement = element as HTMLElement
      htmlElement.style.height = getComputedStyle(htmlElement).height

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(htmlElement).height

      requestAnimationFrame(() => {
        htmlElement.style.height = '0'
      })
    },
  },
})
</script>
