<template>
  <div
    v-click-outside="() => switchOpen(null)"
    class="crypkit-currencies-switcher"
  >
    <div class="flex relative">
      <div class="w-1/2 pr-1" style="padding-right: 2px">
        <CurrencyItem
          id="navigation-crypto"
          :currency="crypto"
          type="crypto"
          amount="1.00"
          :opened="currentType === 'crypto'"
          :loading="loading"
          @open="switchOpen"
          @search="search = $event"
          @select-hovered="selectHovered"
          @item-up="handleArrowKey('up')"
          @item-down="handleArrowKey('down')"
          @close="switchOpen(null)"
        />
      </div>

      <div class="arrows-icon">
        <SvgIcon icon="Arrows" />
      </div>

      <div class="w-1/2" style="padding-left: 2px">
        <CurrencyItem
          id="navigation-fiat"
          :currency="fiat"
          type="fiat"
          :amount="rate"
          :opened="currentType == 'fiat'"
          :loading="loading"
          @open="switchOpen"
          @search="search = $event"
          @select-hovered="selectHovered"
          @item-up="handleArrowKey('up')"
          @item-down="handleArrowKey('down')"
          @close="switchOpen(null)"
        />
      </div>
    </div>

    <SelectItems
      v-if="currentType"
      ref="items"
      v-model:collapsed="itemsCollapsed"
      :options="slicedOptions"
      :selected-value="selectedValue"
      class="crypkit-currencies-switcher-items"
      @select="changeCurrency"
      @load-more="loadMoreCrypto"
    >
      <template #item-text="{ option }">
        <CurrencyIcon :currency="option" />
        <span v-html="formatFullTextSearch(option)" />
      </template>
      <template v-if="loadingMore" #after-options>
        <LoadingDots />
      </template>
    </SelectItems>
  </div>
</template>

<script lang="ts">
import './CurrenciesSwitcher.scss'

import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'
import BigNumber from 'bignumber.js'
import { cloneDeep } from 'lodash-es'

import { formatFullTextSearch } from '@/helpers/currencies'
import fuzzySearch from '@/helpers/fuzzySearch'
import { useGlobalStore } from '@/stores/global'

import SelectItems from '@/components/controls/SelectItems'
import ClickOutside from '@/components/directives/click-outside'
import CurrencyIcon from '@/components/misc/CurrencyIcon'
import LoadingDots from '@/components/misc/LoadingDots'
import SvgIcon from '@/components/misc/SvgIcon'

import CurrencyItem from './CurrencyItem.vue'

import { type Currency } from '@/types/services/global'
import { type CurrencyType } from './types'

interface CurrenciesSwitcherData {
  currentType: CurrencyType
  defaultLimit: number
  limit: number
  loadingMore: boolean
  search: string
}

export default defineComponent({
  components: {
    CurrencyIcon,
    CurrencyItem,
    LoadingDots,
    SelectItems,
    SvgIcon,
  },

  directives: {
    ClickOutside,
  },

  props: {
    crypto: {
      type: Object as PropType<Currency | null>,
      default: null,
    },
    fiat: {
      type: Object as PropType<Currency | null>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change-currency'],

  data(): CurrenciesSwitcherData {
    const defaultLimit = 100
    return {
      defaultLimit,
      currentType: null,
      limit: defaultLimit,
      loadingMore: false,
      search: '',
    }
  },

  computed: {
    ...mapState(useGlobalStore, [
      'currenciesCrypto',
      'currenciesFiat',
      'currenciesRatio',
    ]),
    itemsCollapsed: {
      get(): boolean {
        return Boolean(this.currentType)
      },
      set(value: boolean): void {
        if (!value) {
          this.currentType = null
        }
      },
    },
    currencies(): Currency[] {
      if (this.currentType == 'crypto') {
        return cloneDeep(this.currenciesCrypto)
      }

      return cloneDeep(this.currenciesFiat)
    },
    options(): Currency[] {
      const { search } = this

      let options = this.currencies

      if (search.length > 0) {
        options = fuzzySearch(search, options, {
          keys: ['symbol', 'name', 'text'],
          // prioritize symbol key
          scoreFn: (a) => Math.max(a[0].score * 1.25, a[1].score, a[2].score),
        })
      } else {
        // delete old search highlighted results
        options.forEach((option: Currency) => delete option.highlight)
      }

      return options.sort((a: Currency, b: Currency) => {
        if (search.length && a.group !== b.group) {
          return a.group == 'fiat' ? -1 : 1
        }
        if (a.last_mcap === b.last_mcap) {
          // check search length for old searchScore
          if (a.searchScore === b.searchScore || search.length === 0) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          }
          return (b.searchScore || 0) - (a.searchScore || 0)
        }
        return new BigNumber(b.last_mcap || 0)
          .minus(new BigNumber(a.last_mcap || 0))
          .toNumber()
      })
    },
    slicedOptions(): Currency[] {
      return this.options.slice(0, this.limit)
    },
    selectedValue(): Currency | null {
      const { currentType, crypto, fiat } = this

      let valueId: string | null = null
      if (currentType == 'crypto' && crypto) {
        valueId = crypto.id
      } else if (currentType == 'fiat' && fiat) {
        valueId = fiat.id
      }

      if (valueId == null) {
        return null
      }

      return (
        this.currencies.find((curr: Currency) => curr.id === valueId) || null
      )
    },
    rate(): string | null {
      const { currenciesRatio } = this

      if (currenciesRatio.loading) {
        return null
      }

      return currenciesRatio.ratio !== null
        ? new BigNumber(1 / currenciesRatio.ratio).decimalPlaces(2).toFormat()
        : '-'
    },
  },

  methods: {
    formatFullTextSearch,
    switchOpen(type: CurrencyType): void {
      if (this.currentType == type) {
        this.currentType = null
        return
      }
      this.currentType = type
      this.search = ''
      this.limit = this.defaultLimit
    },
    changeCurrency(currency: Currency): void {
      this.$emit('change-currency', {
        currency,
        type: this.currentType,
      })
      this.currentType = null
    },
    loadMoreCrypto(): void {
      if (this.loadingMore) {
        return
      }

      const shouldLoad = this.options.length > this.limit
      if (shouldLoad) {
        this.loadingMore = true
        // The timeout is here for better UX.
        // It gives users the illusion of loading more currencies
        setTimeout(() => {
          this.limit += 100
          this.loadingMore = false
        }, 750)
      }
    },
    handleArrowKey(direction: 'up' | 'down'): void {
      if (!this.currentType) {
        return
      }

      const items = this.$refs.items as InstanceType<typeof SelectItems>
      if (direction == 'up') {
        items.hoverPrev()
      } else {
        items.hoverNext()
      }
    },
    selectHovered(): void {
      const items = this.$refs.items as InstanceType<typeof SelectItems>
      if (items.hasHovered()) {
        items.selectHovered()
      }
    },
  },
})
</script>
