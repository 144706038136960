<template>
  <Modal
    :show="modalShowed"
    :title="$t('ui.bug_reporting.title')"
    icon="Bug"
    size="lg"
    :error-message="bugReport.errorMessages.value"
    hide-buttons
    @close="modalShowed = false"
  >
    <template v-if="!noteFormShowed">
      <div class="md:grid md:grid-cols-2 md:gap-4">
        <BasicInfoView
          v-for="(category, idx) in basicInfo"
          :key="idx"
          :category="category"
          class="mb-4"
        />
      </div>

      <RequestErrorsView :errors="reqErrors" />

      <ConsoleErrorsView :errors="consoleErrors" class="mt-4" />

      <div class="flex justify-end flex-wrap mt-6">
        <Button
          icon="Trash"
          type="secondary"
          class="mr-auto"
          @click="clearErrors()"
        >
          {{ $t('ui.bug_reporting.clear_errors') }}
        </Button>
        <Button
          icon="ExportDownload"
          type="secondary"
          :disabled="!bugReport.hasError.value"
          @click="bugReport.download()"
        >
          {{ $t('ui.bug_reporting.download_report') }}
        </Button>
        <Button
          class="ml-4"
          :disabled="!bugReport.hasError.value"
          @click="showNoteForm()"
        >
          {{ $t('ui.bug_reporting.send_report') }}
          <SvgIcon icon="Next" class="ml-2" />
        </Button>
      </div>
    </template>
    <template v-else>
      <TextArea
        v-model="customerNote"
        :label="$t('ui.bug_reporting.customer_description')"
        :placeholder="$t('ui.bug_reporting.customer_description_placeholder')"
      />
      <div class="flex justify-between mt-6">
        <Button
          icon="Back"
          type="secondary"
          :disabled="sending"
          @click="noteFormShowed = false"
        >
          {{ $t('ui.common.back') }}
        </Button>
        <Button
          icon="Send"
          :disabled="!bugReport.hasError.value"
          :loading="sending"
          @click="sendReport()"
        >
          {{ $t('ui.bug_reporting.send_report') }}
        </Button>
      </div>
    </template>
  </Modal>

  <transition name="fade">
    <div
      v-if="!isProdBuild() && bugReport.hasError.value"
      class="fixed right-6 bottom-6 rounded-full bg-red-53 w-8 h-8 text-white p-0.5 text-center cursor-pointer hover:bg-red-43 shadow-lg"
      @click="showModal"
    >
      <SvgIcon icon="Bug" size="lg" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { isProdBuild } from '@/helpers/system'
import Button from '@/components/controls/Button'
import TextArea from '@/components/controls/TextArea'
import Modal from '@/components/misc/Modal'
import SvgIcon from '@/components/misc/SvgIcon'
import { useBugReport } from './bug_report'
import BasicInfoView from './BasicInfoView.vue'
import ConsoleErrorsView from './ConsoleErrorsView.vue'
import RequestErrorsView from './RequestErrorsView.vue'
import type {
  BasicInfoCategory,
  ConsoleError,
  RequestError,
} from './bug_report'

const bugReport = useBugReport()
const modalShowed = ref(false)
const basicInfo = ref<BasicInfoCategory[]>([])
const reqErrors = ref<RequestError[]>([])
const consoleErrors = ref<ConsoleError[]>([])
const sending = ref(false)
const customerNote = ref('')
const noteFormShowed = ref(false)

function handleKeyDown(event: KeyboardEvent) {
  if (event.altKey && event.code === 'KeyB') {
    showModal()
  }
}

function showModal() {
  basicInfo.value = bugReport.getBasicInfo()
  reqErrors.value = bugReport.getRequestErrors()
  consoleErrors.value = bugReport.getConsoleErrors()
  modalShowed.value = true
}

function showNoteForm() {
  customerNote.value = ''
  noteFormShowed.value = true
}

async function sendReport() {
  sending.value = true
  await bugReport.send(customerNote.value)
  bugReport.clearErrors()
  modalShowed.value = false
  noteFormShowed.value = false
  sending.value = false
  customerNote.value = ''
}

function clearErrors() {
  bugReport.clearErrors()
  modalShowed.value = false
}

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown)
})
</script>
