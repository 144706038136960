import {
  addMinutes,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  parse,
  parseISO,
  subDays,
  subMinutes,
} from 'date-fns'

const DEFAULT_BEGIN_DATE = '1970-01-01T00:00:00+0000'

/**
 * Checks whether passed argument is Date object or not
 * @param {any} date
 * @returns {boolean}
 */
const isDate = (date: any): boolean => {
  return Boolean(date) && typeof date.getMonth === 'function'
}

/**
 *
 * @param {string|Date|number|null} date - the string from back-end (i.e. 2020-02-22T00:00:00+0000)
 * @param {*} f - format of returned date
 * @param {boolean} iso - is date string in ISO 8601 format
 * @returns {string} - Return formatted date
 */
const formatDateHelper = (
  date: string | Date | number | null,
  f = 'dd/MM/yyyy',
  iso = true
): string => {
  if (!date) {
    return ''
  }

  if (typeof date === 'number') {
    return format(new Date(date), f)
  }

  if (isDate(date)) {
    return format(date as Date, f)
  }

  const stringDate = date as string
  try {
    if (iso) {
      const parsed = parseISO(stringDate)
      return format(parsed, f)
    } else {
      return stringDate.substring(0, 10)
    }
  } catch (err) {
    // Return empty string if provided date can't be parsed
    return ''
  }
}

/**
 *
 * @param {string} string - Date string
 * @returns {Date} - Date object
 */
const stringToUTCDate = (string: string): Date => {
  return parseISO(string)
}

const getStartOfTheYear = (): Date => {
  return new Date(new Date().getFullYear(), 0, 1)
}

const getEndOfTheYear = (): Date => {
  return new Date(new Date().getFullYear(), 11, 31)
}

/**
 * Converts Date object to UTC string with timezone offset
 *
 * @param {Date} date - Date object
 * @returns {string} - String in UTC with timezone offset
 */
const dateToUTCString = (date: Date): string => {
  // This doesn't convert time to UTC, but rather
  // removes local timezone offset and append 'Z' at the end
  const dateUTC = addMinutes(date, date.getTimezoneOffset())
  return format(dateUTC, "yyyy-MM-dd'T'HH:mm:ss'+00:00'")
}

const currentDateInUTCString = (): string => {
  return dateToUTCString(new Date())
}

/**
 * Returns the time before the last UTC midnight
 *
 * @returns {Date}
 */
const endOfUTCDate = (): Date => {
  const current = new Date()
  let endCurrent = subMinutes(endOfDay(current), current.getTimezoneOffset())
  if (endCurrent.getTime() > current.getTime()) {
    endCurrent = subDays(endCurrent, 1)
  }
  return endCurrent
}

/**
 *
 * @param {Date} date - Date object
 * @returns {string} - String in ISO format with timezone offset
 * and without milliseconds
 */
const dateToString = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxx")
}

/**
 * Converts numeric timezone offset to military offset format
 * (i.e. 10.5 -> +10:30)
 */
const militaryGMTOffsetFromNumeric = (offset: number): string => {
  let sign = '+'
  if (offset < 0) {
    sign = '-'
    offset *= -1
  }
  const hours = '0' + Math.floor(offset).toString()
  const minutes = '0' + Math.round((offset % 1) * 60).toString()
  return (
    sign +
    hours.substr(hours.length - 2) +
    ':' +
    minutes.substr(minutes.length - 2)
  )
}

const yesterdayDate = (): Date => {
  const d = new Date()
  d.setDate(d.getDate() - 1)
  return d
}

const formatDateWithTime = (
  date: Date,
  mins: number,
  dateFormat: string,
  notFuture: boolean
): string => {
  date = new Date(date.getTime())
  const hours = mins < 0 ? Math.ceil(mins / 60) : Math.floor(mins / 60)
  date.setHours(hours)
  date.setMinutes(mins % 60)
  date.setSeconds(0)
  let utcDate = format(date, dateFormat)
  if (notFuture) {
    /*
     * pass through the cycle because the user may have
     * a different time zone than the entity and thus may be
     * ahead by 2 days
     */
    while (new Date(utcDate).getTime() > new Date().getTime()) {
      date = subDays(date, 1)
      utcDate = format(date, dateFormat)
    }
  }

  return utcDate
}

const isIsoUTCDateTime = (str: string): boolean => {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+.](\d{3}?0?|\d{2}:\d{2})/u.test(
    str
  )
}

/**
 * Convert period to first or last date of period.
 * Formats of periods:
 * - daily, eg: "2021-01-01", 2021-12-31", ...
 * - weekly, eg: "2020-12-28 - 2022-01-03", ...
 * - monthly, eg: "2022 Jan", "2022 Dec", ...
 * - quarterly, eg: "2022 Q1", "2022 Q4", ...
 * - yearly, eg: "2022", "2023", ...
 * @param period Type of period. (daily, weekly, monthly, quarterly, yearly)
 * @param value Value to convert
 * @param end Convert to last date
 * @returns Date First or last date of period
 */
const periodToDate = (period: string, value: string, end = false): Date => {
  const date = new Date(0)

  if (period == 'daily') {
    const parsedDate = parse(value, 'yyyy-MM-dd', date)
    return end ? endOfDay(parsedDate) : parsedDate
  } else if (period == 'weekly') {
    const [dateFrom, dateTo] = value.split(' - ')
    if (end) {
      return endOfDay(parse(dateTo, 'yyyy-MM-dd', date))
    }
    return parse(dateFrom, 'yyyy-MM-dd', date)
  } else if (period == 'monthly') {
    const parsedDate = parse(value, 'yyyy MMM', date)
    return end ? endOfMonth(parsedDate) : parsedDate
  } else if (period == 'quarterly') {
    const parsedDate = parse(value, 'yyyy qqq', date)
    return end ? endOfQuarter(parsedDate) : parsedDate
  } else if (period == 'yearly') {
    const parsedDate = parse(value, 'yyyy', date)
    return end ? endOfYear(parsedDate) : parsedDate
  }

  return date
}

const secToHumanReadableTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  return `${hours}h ${mins}m`
}

export {
  DEFAULT_BEGIN_DATE,
  isDate,
  formatDateHelper,
  stringToUTCDate,
  dateToUTCString,
  currentDateInUTCString,
  endOfUTCDate,
  dateToString,
  getStartOfTheYear,
  getEndOfTheYear,
  militaryGMTOffsetFromNumeric,
  yesterdayDate,
  formatDateWithTime,
  isIsoUTCDateTime,
  periodToDate,
  secToHumanReadableTime,
}
