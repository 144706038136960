<template>
  <Select
    :model-value="selectedValue"
    class="crypkit-currency-select"
    v-bind="$attrs"
    :name="name"
    :options="slicedOptions"
    searchable
    :custom-search-handler="searchCrypto"
    :disabled="disabled"
    :inline="inline"
    :help="help"
    :bulky-border="bulkyBorder"
    items-class="crypkit-currency-select-items"
    @update:model-value="(currency) => $emit('update:modelValue', currency)"
    @load-more="loadMoreCrypto"
  >
    <template v-if="$slots['selected-text-prepend']" #selected-text-prepend>
      <slot name="selected-text-prepend" />
    </template>
    <template #item-text="{ option }">
      <CurrencyIcon :currency="option" />
      <span v-html="formatFullTextSearch(option)" />
    </template>
    <template #selected-text="{ selected }">
      <span v-if="selected" class="flex items-center min-w-9">
        <CurrencyIcon :currency="selected" />
        {{ selected.symbol }}
      </span>
    </template>
    <template v-if="loading" #after-options>
      <LoadingDots />
    </template>
  </Select>
</template>

<script lang="ts">
import './CurrencySelect.scss'

import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'
import BigNumber from 'bignumber.js'
import { cloneDeep } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'

import { formatFullTextSearch } from '@/helpers/currencies'
import fuzzySearch from '@/helpers/fuzzySearch'
import { useGlobalStore } from '@/stores/global'

import Select from '@/components/controls/Select'
import CurrencyIcon from '@/components/misc/CurrencyIcon'
import LoadingDots from '@/components/misc/LoadingDots'

import { type Currency } from '@/types/services/global'

export default defineComponent({
  components: {
    Select,
    CurrencyIcon,
    LoadingDots,
  },

  props: {
    modelValue: {
      type: Object as PropType<Currency | null>,
      default: null,
    },
    type: {
      type: String,
      default: 'all',
    },
    name: {
      type: String,
      default: () => uuidv4(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: null,
    },
    bulkyBorder: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      loading: false,
      limit: 100,
      search: '',
      selectedValue: null as Currency | null,
    }
  },

  computed: {
    ...mapState(useGlobalStore, [
      'allCurrencies',
      'currenciesCrypto',
      'currenciesFiat',
    ]),
    currenciesByType() {
      switch (this.type) {
        case 'fiat':
          return cloneDeep(this.currenciesFiat)
        case 'crypto':
          return cloneDeep(this.currenciesCrypto)
        case 'all':
          return cloneDeep(this.allCurrencies)
        default:
          return cloneDeep(this.allCurrencies)
      }
    },
    filteredOptions() {
      const search = this.search
      let options = this.currenciesByType

      if (search && search.length > 0) {
        options = fuzzySearch(search, options, {
          keys: ['symbol', 'name', 'text'],
          // prioritize symbol key
          scoreFn: (a) => Math.max(a[0].score * 1.25, a[1].score, a[2].score),
        })
      } else {
        // delete old search highlighted results
        options.forEach((option) => delete option.highlight)
      }
      return options.sort((a: Currency, b: Currency) => {
        if (search.length && a.group !== b.group) {
          return a.group == 'fiat' ? -1 : 1
        }
        if (a.last_mcap === b.last_mcap) {
          // check search length for old searchScore
          if (a.searchScore === b.searchScore || search.length === 0) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          }
          return (b.searchScore || 0) - (a.searchScore || 0)
        }
        return new BigNumber(b.last_mcap || 0)
          .minus(new BigNumber(a.last_mcap || 0))
          .toNumber()
      })
    },
    slicedOptions() {
      return this.filteredOptions.slice(0, this.limit)
    },
  },

  watch: {
    modelValue(currency) {
      if (this.selectedValue != currency) {
        this.setSelectedValue(currency)
      }
    },
  },

  created() {
    this.setSelectedValue(this.modelValue)
  },

  methods: {
    formatFullTextSearch,
    searchCrypto(search: string) {
      this.limit = 100
      this.search = search
    },
    loadMoreCrypto() {
      if (this.loading) {
        return
      }
      const shouldLoad = this.filteredOptions.length > this.limit
      if (shouldLoad) {
        this.loading = true
        // The timeout is here for better UX.
        // It gives users the illusion of loading more currencies
        setTimeout(() => {
          this.limit += 100
          this.loading = false
        }, 750)
      }
    },
    setSelectedValue(currency: Currency | null) {
      if (currency) {
        let mCurrency = this.currenciesByType.find(
          (curr) => curr.id === currency.id
        )
        if (!mCurrency) {
          mCurrency = currency
        }
        this.selectedValue = mCurrency
      } else {
        this.selectedValue = null
      }
    },
  },
})
</script>
